.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.MuiListItem-padding {
  cursor: pointer !important;
}

.lineBreak {
  display: block;
}

.select__control {
  height: 56px !important;
}

.basic-multi-select {
  z-index: 1 !important;
}

.MuiInputBase-input {
  background-color: transparent !important;
}

.MuiDataGrid-cell {
  max-height: 100% !important;
  padding: 5px !important;
}

.MuiLinearProgress-root {
  height: 10px !important;
  border-radius: 5px !important;
}

.MuiDataGrid-row {
  max-height: 100% !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.filepicker {
  background-color: #fff !important;
  border: 2px dashed #006ac6 !important;
  height: 100% !important;
}

.MuiListItemIcon-root {
  min-width: 34px !important;
}

.react-confirm-alert-overlay {
  background: rgb(159 2 2 / 82%) !important;
  z-index: 1500 !important;
}

.deleteConfirm {
  width: 500px;
  background-color: #fff;
  color: #000;
  text-align: center;
  padding: 45px;
}

.Toastify__toast-container {
  word-break: break-word;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.react-rte-demo {
  min-height: 110px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.rdw-editor-main {
  background-color: #fff;
  padding: 5px;
  border: 1px solid #6e6c6c1f;
}

.public-DraftEditor-content {
  line-height: 0.5;
}

.MuiInputBase-input {
  background-color: #fff !important;
}

.select__value-container {
  margin: -6px 0 0px -10px !important;
  padding: 0 8px !important;
}

.select__indicator svg {
  width: 16px;
  height: 16px;
}

.select__indicators-container {
  margin: -2px 22px 3px 0;
}

.documentList {
  width: 100% !important;
}

.MuiAutocomplete-root .MuiAutocomplete-inputRoot .MuiAutocomplete-input {
  width: 0;
  min-width: 30px;
  height: 9px;
  font-size: 14px;
}



.MuiSelect-select {
  height: 40px !important;
  min-height: unset !important;
  line-height: 40px !important; 
  font-size: 14px !important;
  padding-left: 12px !important; 
  box-sizing: border-box !important;
  padding-top: 0px !important;
}


.ltLabelSelected{
  margin-top: 0px;
  cursor: pointer;
  text-align: left;
  text-transform: capitalize;
  background-color: rgba(253,255,50, 50%) !important;
  width: 100%;
  border: none !important;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
  padding: 4px  !important;
  padding-left: 10px !important;
}

.ltLabelUnselected {
  margin-top: 0px;
  cursor: pointer;
  width: 100%;
  text-align: left;
  text-transform: capitalize;
  width: 100%;
  border: none !important;
  padding: 4px  !important;
  padding-left: 10px !important;
}

.textList {
  background-color: white;
  width: 100%;
  font-size: 14px;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
  text-align: left;
  padding-left: 8px;
  cursor: pointer;
}

.ltUnSelected {
  color: #646464 !important;
  cursor: pointer;
  text-align: left  !important;
  text-transform: none !important;
  justify-content: left !important;
  width: 100%;
  padding-top: 2px;
  font-size: 15px;
}

.ltSelected {
  color: #006ac6 !important;
  font-weight: bold;
  cursor: pointer;
  width: 100%;
  text-align: left !important;
  text-transform: none  !important;
  justify-content: left !important;
  padding-top: 2px;
  font-size: 15px;
}

.textList {
  background-color: white;
  width: 100%;
  font-size: 14px;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
  text-align: left;
  padding-left: 8px;
  cursor: pointer;
}