.floating-button {
    position: fixed;
    bottom: 40px;
    right: 40px;
    display: flex;
    align-items: center;
    background: linear-gradient(to right, #38a169, #4c51bf);
    border-radius: 16px; /* Ensures rounded corners */
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    overflow: hidden;
    width: 50px;
    height: 50px;
    padding: 10px;
    transition: width 0.3s ease-in-out, border-radius 0.3s ease-in-out;
    z-index: 999;
  }
  
  .icon {
    width: 22px;
    height: 22px;
    transition: transform 0.3s ease-in-out;
    margin-left: 5px;
  }
  
  .text {
    color: white;
    font-weight: bold;
    margin-left: 10px;
    opacity: 0;
    white-space: nowrap;
    transition: opacity 0.2s ease-in-out;
  }
  
  /* Hover effect */
  .floating-button:hover {
    width: 170px;
    border-radius: 25px; /* Slightly more rounded corners when expanded */
  }
  
  .floating-button:hover .text {
    opacity: 1;
  }
  /* Floating Assistant */
.assistant-container {
    position: fixed;
    bottom: 80px;
    right: 20px;
    width: 320px;
    background: white;
    border-radius: 12px;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    font-family: Arial, sans-serif;
    animation: fadeIn 0.3s ease-in-out;
  }
  
  /* Header */
  .assistant-header {
    background: linear-gradient(to right, #38a169, #4c51bf);
    color: white;
    padding: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: bold;
  }
  
  .beta-badge {
    font-size: 10px;
    background: white;
    color: #38a169;
    padding: 2px 5px;
    border-radius: 5px;
    margin-left: 5px;
    margin-top: 1px;
    position: absolute;
  }
  
  .close-btn {
    background: none;
    border: none;
    color: white;
    font-size: 16px;
    cursor: pointer;
  }
  
  /* Content */
  .assistant-content {
    padding: 15px;
  }
  
  .greeting {
    font-size: 16px;
    font-weight: bold;
  }
  
  .highlight {
    color: #38a169;
  }
  
  .subtext {
    color: gray;
    margin-bottom: 10px;
  }
  
  /* Options */
  .assistant-options {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .option {
    background: white;
    border: 1px solid #ddd;
    padding: 10px;
    text-align: left;
    font-size: 14px;
    border-radius: 8px;
    cursor: pointer;
    transition: background 0.2s;
  }
  
  .option:hover {
    background: #f0f0f0;
  }
  
  /* Footer */
  .assistant-footer {
    display: flex;
    padding: 10px;
    border-top: 1px solid #ddd;
  }
  
  .assistant-footer input {
    flex-grow: 1;
    border: none;
    padding: 8px;
    border-radius: 8px;
    outline: none;
  }
  
  .send-btn {
    background: #38a169;
    color: white;
    border: none;
    padding: 8px 12px;
    margin-left: 5px;
    border-radius: 8px;
    cursor: pointer;
  }
  .assistant-chat {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 15px;
    background: #f9f9f9;
    border-radius: 12px;
    max-height: 300px;
    overflow-y: auto;
  }
  .user-message {
    background: #38a169;
    color: white;
    padding: 10px;
    border-radius: 8px;
    align-self: flex-end;
    max-width: 70%;
  }
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }