.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: url('../../assets/leftdetail.png') bottom left no-repeat,
                url('../../assets/rightdetail.png') top right no-repeat,
                linear-gradient(to right, #001220, #002b45);
    background-size: auto, auto, cover;
  }

.login-box {
    padding: 30px;
    border-radius: 10px;
    background: white;
    width: 400px;
    text-align: center;
}

.logo {
    font-weight: bold;
    color: #007bff;
}

.logo-green {
    color: green;
}

.login-button {
    background-color: #007bff !important;
    color: white !important;
    margin-top: 15px;
}

.continue-text {
    margin-top: 20px;
}
.MuiInputAdornment-root .MuiIconButton-root {
    color: #a0a0a0; /* Light gray color */
  }
  
  .MuiInputAdornment-root .MuiIconButton-root:hover {
    color: #6c6c6c; /* Slightly darker on hover */
  }
.social-button {
    margin-top: 10px;
    border-radius: 5px !important;
}

.forgot-password {
    display: block;
    margin-top: 15px;
    color: #007bff;
}

.buttonText {
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: bold !important;
    line-height: 150% !important;
    text-transform: none !important;
   color: #000000 !important; 
   
    /* 24px */
}

.labelText{
    font-weight: bold !important;
    color: #323639 !important;
}
