.MuiListItem-root {
  padding-left: 15px !important;
}

.layout-container-layout {
  display: flex;
  height: 100vh;
}

.sidebar-layout {
  flex: 0 0 auto;
  overflow-y: auto;
  transition: width 0.7s ease-in-out, justify-content 0.7s ease-in-out;
}

.body-layout {
  flex: 1;
  overflow-y: hidden;
}

.header-layout {
  background: #1976d2;
  height: 64px;
  width: 100%;
  display: inline-block;
  position: relative;
}
