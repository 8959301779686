  
  .text {
    color: white;
    font-weight: bold;
    margin-left: 10px;
    opacity: 0;
    white-space: nowrap;
    transition: opacity 0.2s ease-in-out;
  }
  
  /* Floating agent */
.agent-container {
  display: table-row;
  padding: 4px;
  padding-top: 40px;
  position: relative;
    width: 100%;
    background: white;
    font-family: Arial, sans-serif;
    animation: fadeIn 0.3s ease-in-out;
    bottom: 38px;
    font-size: 14px !important;
    text-align: left;

    flex: 1; /* Matches parent height */

    display: flex;
    flex-direction: column;
    height: 100%; /* Ensures full height */
    background: white;
    overflow-y: auto;
    overflow-x: hidden  ;
  }
  
  .agent-greeting{
    position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-40%, -50%);
  text-align: center;
  }

  .send{
    cursor: pointer;
  }
  /* Header */
  .agent-header {
    color: black;
    font-weight: bold;
    padding-top: 0px;
    display: flex;
    justify-content: normal;
    font-weight: bold;
    align-items: center;
    position: relative;
    z-index: 999;
    background-color: #fff;
  }
  .agent-header img {
    width: 24px;
    height: 24px;
    border-radius: 50%;
  }
  .agent-header span {
    flex-grow: 1;
    margin-left: 10px;
    text-align: left;
  }
  
  .beta-badge {
    font-size: 10px;
    background: white;
    color: #38a169;
    padding: 2px 5px;
    border-radius: 5px;
    margin-left: 5px;
    margin-top: 1px;
    position: absolute;
  }
  
  .close-btn {
    background: none;
    border: none;
    color: white;
    font-size: 16px;
    cursor: pointer;
  }
  
  /* Content */
  .agent-content {
    flex: 1; /* Fills remaining height */
    overflow-y: auto; /* Enables scrolling */
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-bottom: 40px;
  }
  .agent-chat {
    flex-direction: column;
    gap: 10px;
    padding: 12px;
    border-radius: 12px;
    overflow-y: auto;
  }

  
  .greeting {
    font-size: 16px;
    font-weight: bold;
    line-height: 2px;
  }
  
  .highlight {
    font-size: 48px;
    font-weight: bold;
    background: linear-gradient(90deg, #7619D2 0%, #1976D2 45%, #0DCC5C 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    color: transparent;
    font-size: 16px;
    }
  
    .doc-references-title {
    font-weight: bold !important;
    margin-top: 10px;
    margin-bottom: 10px;
    color: darkgray;
  }
  .subtext {
    color: gray;
    margin-bottom: 10px;
  }
  .timer-text {
    font-size: 12px;
    color: white;
    background-color: #555;
    border-radius: 6px;
    padding: 2px 4px;
    margin-top: 0px;
    
  }

  .agent-loading {
    color: #555;
    font-size: 14px;
    position: absolute;
    margin-top: 10px;
    padding: 2px 4px;
    border-color: #d5d6dd;
    
  }
  /* Options */
  .agent-options {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .option {
    background: white !important;
    border: 1px solid #ddd;
    padding: 10px;
    text-align: left;
    font-size: 14px;
    border-radius: 8px;
    cursor: pointer;
    transition: background 0.2s;
  }
  .doc-references{
    color: darkgray;
    cursor: pointer;
  }
  .doc-reference:hover{
    color: #000;
  }
  .option:hover {
    background: #f0f0f0;
  }
  
  /* Footer */
  .agent-footer {
    display: block;
    padding-top: 10px;
    bottom: 0;
    width: 100%;
    z-index: 999;
  }
  
  .agent-input{
    border: 1px solid #ddd;
    display: flex;
    gap: 10px;
    padding: 10px;
    background: white;
    border-radius: 8px;
    margin-top: 20px;
  }
  .agent-footer input {
    flex-grow: 1;
    border: none;
    border-radius: 8px;
    outline: none;
  }
  
  .send-btn {
    background: #38a169;
    color: white;
    border: none;
    padding: 8px 12px;
    margin-left: 5px;
    border-radius: 8px;
    cursor: pointer;
  }
  .user-message {
    background: #E7F3FF;
    color: white;
    padding: 10px;
    border-radius: 8px;
    align-self: flex-end;
    max-width: 100%;
    color: black;
    text-align: right;
  }
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  .typing-loader {
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #555;
}

.typing-loader span {
  animation: blink 1.5s infinite;
}

.typing-loader span:nth-child(2) {
  animation-delay: 0.2s;
}
.typing-loader span:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes blink {
  0% { opacity: 0.2; }
  20% { opacity: 1; }
  100% { opacity: 0.2; }
}

.typing-indicator {
  display: inline-flex;
  align-items: center;
  gap: 5px;
  height: 24px;
  margin-left: 8px;
}

.typing-dot {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: #006ac6;
  animation: bounce 1.2s infinite ease-in-out;
}

.typing-dot:nth-child(1) {
  animation-delay: 0s;
}
.typing-dot:nth-child(2) {
  animation-delay: 0.2s;
}
.typing-dot:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes bounce {
  0%, 80%, 100% {
    transform: scale(0.8);
    opacity: 0.3;
  }
  40% {
    transform: scale(1.2);
    opacity: 1;
  }
}